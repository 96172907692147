@charset "utf-8";

$display-font-sizes: (
  1: 4rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem
);

@import "bootstrap/stylesheets/bootstrap";
@import "leaflet/leaflet.css";
@import "leaflet-locatecontrol/L.Control.Locate.min.css";
@import "marker-cluster";

$stolperstein-light: $yellow-300;
$stolperstein-dark: $yellow-600;

#navbarSupportedContent .ml-auto .dropdown-menu {
  text-align: right;
  right: 0;
  left: auto;
}

#home-slider {
  background-size: cover;
  background-position: center center;

  .container-lg {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .biografie-col {
    margin: auto 0;
  }
}

.biografie-main {
  background: rgb(226,176,7);
  background: linear-gradient(120deg, rgba(226,176,7,1) 10%, rgba(248,204,53,1) 40%, rgba(180,140,5,1) 100%);
  border-color: #987605;
  border-width: 8px;
  border-radius: 4px;
  border-style: solid;
  cursor: pointer;
}

.inscription,
.stolperstein {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(226,176,7);
  background: linear-gradient(120deg, rgba(226,176,7,1) 10%, rgba(248,204,53,1) 40%, rgba(180,140,5,1) 100%);
  border-color: #987605;
  border-width: 8px;
  border-radius: 4px;
  border-style: solid;
  line-height: 1.25em;
  color: $gray-900;
  font-size: 0.75em;
  font-family: courier;
  cursor: pointer;
}
.inscription:before,
.stolperstein:before {
  content: "";
  float: left;
  padding-top: 100%;
}

#popup-map {
  height: 150px;
}

@include media-breakpoint-up(sm) {
  .inscription,
  .stolperstein {
    font-size: 0.8em;
  }

  #popup-map {
    height: 200px;
  }
}

@include media-breakpoint-up(md) {
  .inscription,
  .stolperstein {
    font-size: 0.85em;
  }

  #popup-map {
    height: 300px;
  }
}

@include media-breakpoint-up(lg) {
  .inscription,
  .stolperstein {
    font-size: 0.9em;
  }
}

.leaflet-container,
.leaflet-popup-content {
  font-size: inherit !important;
}

.leaflet-popup-content {
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-height: calc(100vh - 120px);
  overflow: auto;
}

.leaflet-popup-content {
  #popup-map-title,
  #popup-map {
    display: none;
  }
}

.leaflet-popup-scrolled {
  border-width: 0 !important;
}

a {
  @extend .link-secondary;
  text-decoration-color: $stolperstein-light;
  &.row-link {
    display: block;
    img {
      width: 20px;
      height: 20px;
      max-width: unset;
    }
  }
}

.page-item {
  a.page-link {
    @extend .px-2;
    @extend .py-1;
    color: $stolperstein-dark;
  }
  &.active a.page-link {
    background-color: $stolperstein-dark;
    border-color: $stolperstein-dark;
  }
}

.dropdown-menu {
  min-width: 18rem;
}

.bg-stolpersteine {
  background-color: $yellow-300;
  color: $gray-900;
}

img {
  max-width: 100%;
}

blockquote {
  @extend .alert;
  @extend .alert-warning;
  p {
    margin-bottom: 0;
  }
}

.popup-image {
  width: 100%;
}
.popup-image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $width in (25, 33, 50, 66, 75, 100) {
      .w-#{$breakpoint}-#{$width} {
        width: #{$width}% !important;
      }
    }
  }
}

@each $size in (6, 8, 10, 12, 14, 16) {
  .font-#{$size} {
    font-size: #{$size}px !important;
  }
}
